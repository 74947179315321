import React from 'react';

import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {HttpLink, ApolloClient, ApolloProvider, from} from '@apollo/client';
import {RetryLink} from '@apollo/client/link/retry';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {pdfjs} from 'react-pdf';

import initPolyfills from '@renofi/utils/src/polyfills';
import {logGraphQLError, badQueryErrorLink} from '@renofi/utils';
import {fetchWithRateLimitHandler, retryLinkConfig} from '@renofi/api';
import {questionnaireCache} from '@renofi/modules';

import AppWrapper from './App';
import {initAnalytics} from './analytics';
import './index.css';

initPolyfills();
initAnalytics();

// set up react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
const httpLink = new HttpLink({
  uri: apiUrl,
  fetch: fetchWithRateLimitHandler,
});

const retryLink = new RetryLink(retryLinkConfig);

// Log any GraphQL errors or network error that occurred
const errorLink = onError(logGraphQLError);

const authLink = setContext((_, {headers}) => {
  return {headers};
});

const client = new ApolloClient({
  cache: questionnaireCache.cache,
  name: process.env.REACT_APP_SERVICE_NAME,
  version: process.env.REACT_APP_COMMIT_REF,
  link: from([
    errorLink,
    badQueryErrorLink,
    authLink,
    retryLink,
    httpLink,
    errorLink,
  ]),
});

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <AppWrapper />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
